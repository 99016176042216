import React from "react";
import FooterWave from "../assets/FooterWave.svg";

function Footer() {
  return (
    <footer className="bg-transparent static bottom-0">
      <div className="w-screen min-h-[10vh] flex justify-end items-end relative">
        <img
          src={FooterWave}
          className="absolute bottom-0 w-full object-cover"
          alt="ByteSize Footer wave"
        />
      </div>
      <div className="bg-gradient-to-b from-[#5da9ef] to-[#3898ec]"></div>
    </footer>
  );
}

export default Footer;
