import React, { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App(){

  const [selectedGame, setSelectedGame] = useState(null);

  const games = {
    mario: {
      name: "Mario",
      description: "A classic game of jumping over obstacles",
      image: "https://i.imgur.com/9j0XQ7K.png",
      url: "https://mario-byte-size.netlify.app/",
    },
    pong: {
      name: "Pong",
      description: "A classic game of pong",
      image: "https://i.imgur.com/9j0XQ7K.png",
      url: "https://pong-byte-size.netlify.app/",
    },
    spaceInvaders: {
      name: "Space Invaders",
      description: "A classic game of space invaders",
      image: "https://i.imgur.com/9j0XQ7K.png",
      url: "https://space-invaders-byte-size.netlify.app/",
    },
  };

  return (
    <>
      <Header />
      <div className="flex h-[80vh] flex-col items-center">
        <h1 className="text-4xl font-bold text-center w-full my-10">ByteSize Games</h1>
        {selectedGame === null && (
          <div className="grid grid-cols-2 gap-20 p-10 justify-center items-center bg-black w-[50%] mx-20">
            {Object.keys(games).map((game) => (
              <div
                className="flex flex-col items-center justify-center bg-white rounded-lg p-8 cursor-pointer hover:animate-bounce duration-100"
                onClick={() => setSelectedGame(game)}
              >
                <h2 className="text-2xl font-bold mt-5">{games[game].name}</h2>
                <button className="bg-blue-500 text-white w-full py-3 rounded-lg mt-5">
                  Play
                </button>
              </div>
                ))}
            
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default App;
