import React from "react";
import HeaderWave from "../assets/FooterWave.svg";

function Header() {
  return (
    <footer className="bg-transparent static top-0">
      <div className="w-screen min-h-[10vh] flex justify-end items-end relative top-0">
        <img
          src={HeaderWave}
          className="absolute top-0 w-full object-cover rotate-180"
          alt="ByteSize Header wave"
        />
      </div>
      <div className="bg-gradient-to-b from-[#5da9ef] to-[#3898ec]"></div>
    </footer>
  );
}

export default Header;
